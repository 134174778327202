.gameCell {
  height: 5.5vw !important;
  width: 5.5vw !important;
  /* background: #2A1A89; */
  cursor: pointer;
  /* border-radius: 5rem; */
}

table,
td {
  border-collapse: separate;
  border-spacing: 10px;
  font-family: arabicRetro;
}

.connect4-table {
  background-color: #dc30f6 !important;
  padding: 90px;
}


.whiteCircle {
  background-image: url("./assets/connect4/retro/coin_border.svg");
  background-repeat: no-repeat;
  background-size: contain;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;

  cursor: url('./assets/pointer.png') 14 0,pointer;

}

.redCircle {
  background-image: url("./assets/connect4/retro/red_coin.svg");
}

.blueCircle {
  background-image: url("./assets/connect4/retro/blue_coin.svg");
}

.redCircle,
.blueCircle {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  width: 80%;
  height: 80%;
  margin: auto;
  transition: background-image 0s;
  animation-name: fall-animation;
  animation-duration: 800ms;
  transform: translateX(-0.1rem);

  /* margin: auto;
  transform: translateY(-0.375rem);
  margin-top: 6%; */

}

@keyframes fall-animation {
  from {
    transform: translateY(-800px);
    background-color: #fff;
  }
  to {
    transform: translateY(0);
  }
}
