@font-face {
  font-family: englishRetro;
  src: url(./assets/Fonts/upheavtt.ttf);
}

@font-face {
  font-family: arabicRetro;
  src: url(./assets/Fonts/Arabic\ Pixel\ 2.ttf);
}

.arabicText {
  font-family: arabicRetro;

}

.redCoin {
  background-image: url("./assets/connect4/retro/red_coin.svg");
  background-repeat: no-repeat;
  background-size: contain;
}

.blueCoin {
  background-image: url("./assets/connect4/retro/blue_coin.svg");
  background-repeat: no-repeat;
  background-size: contain;
}
.container {
  display: grid;
  grid-template-columns: 5% 50% 40% 5%;
  grid-template-rows: 10% 60% 20% 10%;
  gap: 0px 0px;
  grid-auto-flow: row;
  justify-content: space-evenly;
  align-content: flex-start;
  justify-items: end;
  align-items: center;
  min-width: 100%;
}

.connect4-area {
  grid-area: 1 / 2 / 5 / 3;
  min-width: 100%;
  display: flex;
  justify-content: end;
}
.chatBox-area {
  grid-area: 2 / 3 / 3 / 4;
  min-width: 100%;
  /* min-height: 100%; */
  width: 100%;
  overflow: hidden;
  display: flex;
  justify-content: flex-end;
  align-content: flex-end;
  align-items: flex-end;
}
.player-area {
  grid-area: 3 / 3 / 4 / 4;
  font-family: "englishRetro";
  color: #fff;
  font-size: 50px;
  min-width: 100%;
  min-height: 100%;
  display: flex;
  align-items: flex-start;
  text-align: left;

}
.empty-top {
  grid-area: 1 / 1 / 2 / 5;
}
.empty-bottom {
  grid-area: 4 / 1 / 5 / 5;
}
.empty-right {
  grid-area: 2 / 4 / 4 / 5;
}
.empty-left {
  grid-area: 2 / 1 / 4 / 2;
}

.connect4-container {
  background-image: url("./assets/connect4/retro/board.svg");
  background-repeat: no-repeat;
  background-size: cover;
}

.chatBox {
  background-image: url("./assets/retro_chatbox.svg");
  background-repeat: no-repeat;
  background-size: contain;
  transform: translate(-3.2vw, 0vw);
  min-width: 100%;
  height: 300px;
}

.playerTag {
  margin-left: 50px;
  text-align: left;
}

.winnerBox {
  min-height: 10rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

:root {
  --coin-size: 100%;
  --red-face1: url("./assets/connect4/retro/red_rotation/1.png");
  --red-face2: url("./assets/connect4/retro/red_rotation/2.png");
  --red-face3: url("./assets/connect4/retro/red_rotation/3.png");
  --red-face4: url("./assets/connect4/retro/red_rotation/4.png");
  --red-face5: url("./assets/connect4/retro/red_rotation/5.png");
  --red-face6: url("./assets/connect4/retro/red_rotation/6.png");
  --red-face7: url("./assets/connect4/retro/red_rotation/7.png");
  --red-face8: url("./assets/connect4/retro/red_rotation/8.png");

  --blue-face1: url("./assets/connect4/retro/blue_rotation/1.png");
  --blue-face2: url("./assets/connect4/retro/blue_rotation/2.png");
  --blue-face3: url("./assets/connect4/retro/blue_rotation/3.png");
  --blue-face4: url("./assets/connect4/retro/blue_rotation/4.png");
  --blue-face5: url("./assets/connect4/retro/blue_rotation/5.png");
  --blue-face6: url("./assets/connect4/retro/blue_rotation/6.png");
  --blue-face7: url("./assets/connect4/retro/blue_rotation/7.png");
  --blue-face8: url("./assets/connect4/retro/blue_rotation/8.png");
}

/*
  everything above is positioning and variable.
*/

.redEffect, .blueEffect {
  height: var(--coin-size);
  width: var(--coin-size);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.redEffect::before, .blueEffect::before {
  content: "";
  height: var(--coin-size);
  width: var(--coin-size);
  /* animation-delay: 0.5s; */
  background-size: 100% 100%;
}

.redEffect::before {
  animation: redSpin 1s linear infinite;
  background-image: var(--red-face1);
}
.blueEffect::before {
  animation: blueSpin 1s linear infinite;
  background-image: var(--blue-face1);
}
/* animation definitions */
@keyframes redSpin {
  0% {
    width: var(--coin-size);
  }

  25% {
    background-image: var(--red-face2);
  }

  37.5% {
    background-image: var(--red-face3);
  }

  50% {
    background-image: var(--red-face4);
    animation-timing-function: linear;
  }
  62.5% {
    background-image: var(--red-face5);
    animation-timing-function: linear;
  }
  75% {
    background-image: var(--red-face6);
    transform: rotateY(180deg);
    width: var(--coin-size);
  }
  87.5% {
    background-image: var(--red-face7);
    transform: rotateY(180deg);
  }
  100% {
    background-image: var(--red-face8);
    transform: rotateY(180deg);
  }
}


@keyframes blueSpin {
  0% {
    width: var(--coin-size);
  }

  25% {
    background-image: var(--blue-face2);
  }

  37.5% {
    background-image: var(--blue-face3);
  }

  50% {
    background-image: var(--blue-face4);
    animation-timing-function: linear;
  }
  62.5% {
    background-image: var(--blue-face5);
    animation-timing-function: linear;
  }
  75% {
    background-image: var(--blue-face6);
    transform: rotateY(180deg);
    width: var(--coin-size);
  }
  87.5% {
    background-image: var(--blue-face7);
    transform: rotateY(180deg);
  }
  100% {
    background-image: var(--blue-face8);
    transform: rotateY(180deg);
  }
}