@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  width: 100%;
  height: 100%;
  display: table;
  cursor: url('./assets/cursor.png'),auto;

}

body {
  width: 100%;
  display: table-cell;
  background-image: url("../src/assets/retro_bg.svg");
  background-repeat: no-repeat;
  background-size: cover;
  height: 0;
  padding-top: 66, 64%;
}

html,
body {
  margin: 0;
  padding: 0;
}

body > #root > div,
body > #root {
  height: 100% !important;
}

button {
  cursor: url('./assets/pointer.png') 14 0,pointer;

}